<template>
  <div class="vx-row">
    <!-- Main -->
    <div class="vx-col sm:w-full md:w-1/2 mb-base">
      <vx-card title="Data">
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <browse-contact class="w-full" :iscustomer="true" v-validate="'required'" name="customer_id" label="Customer" v-model="customer_id"></browse-contact>
            <span class="text-danger text-sm" v-show="errors.has('customer_id')">{{errors.first('customer_id')}}</span>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col sm:w-full md:w-1/2">
            <small class="ml-1">Transaction Date</small>
            <flat-pickr :config="flatPickrConfig" class="w-full" v-model="date_transaction"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <vs-input label="Description" class="w-full" v-model="description"></vs-input>
          </div>
        </div>
      </vx-card>
    </div>
    <!-- Receivable -->
    <div class="vx-col sm:w-full md:w-1/2 mb-base">
      <vx-card title="Receivable">
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <browse-receivable @on-change="changeReceivable" :params="{customer_id: customer_id}" label="Find Receivable" readonly class="w-full" v-model="fd.receivable_id"></browse-receivable>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2">
            <vs-input type="number" label="Total Bill / Leftover" readonly class="w-full" v-model="fd.total_bill"></vs-input>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <vs-checkbox v-model="is_pay_all">Pay All</vs-checkbox>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2">
            <vs-input type="number" label="Total Payment" @focus="$event.target.select()" readonly class="w-full" v-model="fd.total_payment"></vs-input>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <vs-input label="Description" class="w-full" v-model="fd.description"></vs-input>
          </div>
        </div>
        <vs-button class="mt-5" size="small" @click="append" icon-pack="feather" icon="icon-plus">Add To Table</vs-button>
      </vx-card>
    </div>
    <!-- Details -->
    <div class="vx-col w-full mb-base">
      <vx-card>
        <vs-table :data="details">
          <template slot="thead">
            <vs-th>Invoice No.</vs-th>
            <vs-th>Total Billed</vs-th>
            <vs-th>Total Payment</vs-th>
            <vs-th>Description</vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(tr, i) in data" :key="i">
              <vs-td>{{tr.code}}</vs-td>
              <vs-td label="Total Billed" class="text-right">{{tr.total_bill|numFormat}}</vs-td>
              <vs-td label="Total Payment" class="text-right">{{tr.total_payment|numFormat}}</vs-td>
              <vs-td label="Description">{{tr.description}}</vs-td>
              <vs-td width="10">
                <vs-button size="small" color="danger" @click="deleteD(i)" icon="icon-delete" icon-pack="feather"></vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="flex">
          <vs-button class="mr-2" @click="store" color="primary" icon-pack="feather" icon="icon-save">Save</vs-button>
          <vs-button :to="{name:'bills'}" type="border" color="warning">Back</vs-button>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import BrowseContact from '@browse/Contact.vue'
import BrowseReceivable from '@browse/Receivable.vue'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      customer_id: null,
      description: "",
      date_transaction: new Date(),
      details:[],
      is_pay_all: false,
      fd:{
        receivable_id: null,
        total_bill: 0,
        total_payment: 0,
        description: "",
        code: ""
      },
      flatPickrConfig: {
        altFormat: 'j M Y',
        altInput: true,
        dateFormat: 'Y-m-d',
      }
    }
  },
  watch:{
    is_pay_all(val) {
      if (val) {
        this.fd.total_payment = this.fd.total_bill
      }
    }
  },
  components: {
    BrowseContact,
    BrowseReceivable,
    flatPickr
  },
  methods:{
    ...mapActions({
      dispatchStore: 'bills/store'
    }),
    changeReceivable(data){
      this.fd.total_bill = data.leftover
      this.is_pay_all = false
      this.fd.code = data.code
    },
    append(){
      if (!this.fd.receivable_id && this.fd.total_payment==0) {
        return this.$vs.notify({
          title: 'Oops!',
          text: 'Please fill data correctly',
          color: 'warning'
        }) 
      }
      this.details.push(this.fd)
      this.fd = {
        receivable_id: null,
        total_bill: 0,
        total_payment: 0,
        description: "",
        code: ""
      }
    },
    deleteD(i) {
      this.details.splice(i, 1)
    },
    store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = {
          customer_id: this.customer_id,
          date_transaction: this.date_transaction,
          description: this.description,
          total_payment: this._.sumBy(this.details, 'total_payment'),
          details: this.details,
        }
        try {
          await this.dispatchStore(payload)
          this.$router.push({name:'bills'})
          this.$vs.notify({
            title: 'OK!',
            text: 'Your data was saved successfully',
            color: 'success'
          })
        } catch (error) {
          this.$vs.notify({
            title: 'Oops!',
            text: error.data.message,
            color: 'danger'
          })
        }
      })
    }
  }
}
</script>

<style>

</style>